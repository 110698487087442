.auth-head {
  @extend %wrapper-min !optional;
  background-color: #C4C4C4;
  height: 50px;
  display: flex;
  align-items: center;

  &__icon {
    width: 24px;
    height: 24px;
    color: #000;
  }
}

.auth {
  position: relative;
  width: 100%;
  height: max-content;
  min-height: calc(100% - 70px);

  &__logo {
    display: flex;
    justify-content: center;
    margin-top: 13px;
    max-width: 100%;
    width: auto;
    height: auto;
  }

  &__form {
    padding: 0 32px;
    width: 100%;
    // overflow: hidden;
    padding-bottom: 42px;
    margin-top: 80px;
  }

  &__back {
    position: absolute;
    z-index: -1;
    bottom: 0;
    left: -30px;
    width: calc(100% + 30px);
    height: calc(100% - 20px);
  }

  &__control {
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: unset;
    }
  }

  &__wrap-control {
    margin-bottom: 28px;
  }

  &__button {
    margin: 0 auto;
    width: 100%;
  }

  &__info {
    padding: 18px 38px;
    background: #fff;
    text-align: center;
    border-radius: 10px;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #FF0000;
    margin-top: 23px;
  }
}
