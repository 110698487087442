.green-box {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 54px;
  justify-content: center;
  text-align: center;
  font-weight: 500;
  font-size: 22px;
  line-height: 54px;
  letter-spacing: 0.5px;
  color: #FFFFFF;
  background: #27AE60;
  cursor: pointer;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 4px 5px rgba(0, 0, 0, 0.12), 0px 1px 10px rgba(0, 0, 0, 0.2);
}
