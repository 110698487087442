.container {
    height: 100% !important;
}
.label {
    text-align: left;
    padding-bottom: 10px;
}
.col-input .input, {
    width: 100%;
    padding: 2px 8px;
    height: 54px;
    border-radius: 4px;
    border: 1px solid hsl(0, 0%, 80%);
}
