.barcode-reader-wrapper {
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 100vh;
    transition: top 450ms;
    background-color: black;
}

.barcode-reader-wrapper.active {
    top: 0;
}

div#reader {
    width: '100vw';
    background-color: 'black';
    height: '100vw';
}