.toggle {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  $self: &;

  &__title {
    font-weight: 500;
    font-size: 11px;
    line-height: 32px;
    letter-spacing: 0.5px;
    color: #8E8E8E;
    margin-bottom: -1px;
  }

  input:checked + * {
    #{$self}__thumb {
      transform: translateX(16px);
      background-color: #27AE60;
    }

    #{$self}__tack {
      background-color: #2AD93C;
    }
  }

  &__wrap {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    padding-right: 4px;
  }

  &__tack {
    display: block;
    width: 36px;
    height: 15px;
    background: #FF6060;
    opacity: 0.5;
    border-radius: 16px;
  }

  &__thumb {
    position: absolute;
    display: block;
    width: 21px;
    height: 21px;
    background: #EB5757;
    border-radius: 50%;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.14), 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
  }
}
