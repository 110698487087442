.orders-tablet {
  &__line {
    display: flex;
    align-items: center;

    &--body {
      padding: 18px 0 18px 0;
      cursor: pointer;

      &:nth-child(odd) {
        background: #EFEFEF;
      }
    }

    &--header {
      margin-bottom: 6px;
    }

    &--yellow {
      border-left: 8px solid #FBBC05;
    }

    &--blue {
      border-left: 8px solid #59D8EA;
    }

    &--green {
      border-left: 8px solid #40C866;
    }
  }

  &__item {
    letter-spacing: 0.5px;

    &--header {
      font-size: 12px;
      color: #777777;
    }

    &--body {
      font-size: 13px;
      color: #4F4F4F;
    }

    &--first {
      padding-left: 30px;
      width: 160px;
      margin-right: 12px;
      flex-shrink: 0;
    }

    &--two {
      width: 81px;
      flex-shrink: 0;
    }

    &--three {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 17px;
    }
  }
}
.blurred {
    -webkit-filter: blur(6px);
    filter: blur(6px);
}

.orders {

  &__top {
    margin-top: 25px;
    padding-left: 24px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 17px;
  }

  &__title {
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 0.5px;
    color: #000000;
  }

  &__date {
    padding-left: 24px;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.5px;
    color: #4B4B4B;
    margin-top: 13px;
    margin-bottom: 6px;
  }

  &__list {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 16px;
    padding-right: 20px;

    & > * {
      margin-right: 9px;

      &:last-child {
        margin-right: unset;
      }
    }
  }
}

.orders-tablet__line--body:nth-child(odd) {
    background: #EFEFEF;
}
.orders-tablet__line--green {
    border-left: 8px solid #40C866;
}
.orders-tablet__line--body {
    padding: 18px 0 18px 0;
    cursor: pointer;
}
.orders-tablet__line {
    display: flex;
    align-items: center;
}

.die {
  padding: 5px 6px 5px 11px;
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.28);
  border-radius: 5px;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.5px;
  color: #FFFFFF;
  width: max-content;

  &--blue {
    background: #59D8EA;
  }

  &--green {
    background: #40C866;
  }

  &--yellow {
    background: #FBBC05;
  }
}

