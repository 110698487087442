.stand {
    position: absolute;
    border: 1px solid #464646;
    border-radius: 3px;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    background: linear-gradient(#d2e8f3, #70afd0);
}

.stand span {
    pointer-events: none;
    display: inline-block;
    vertical-align: middle;
    line-height: normal;
    word-break: break-all;
}

.stand.dropzone {
    border: 1px dashed grey;
    background: linear-gradient(#eaeaea, #fff);
}

.stand.highlighted {
    background: linear-gradient(#ffca32, #f59300);
}

.stand.highlighted.selected {
    background: linear-gradient(#1af775, #0ea04b);
}


.stand.type-area {
    background: rgba(0, 0, 0, 0.2);
    border-width: 3px;
    border-style: dashed;
}