.map-container {
    margin: 0 auto;
    overflow:scroll;
    background-color: #fff;
    height: 60vh;
    border: 1px solid grey;
}
.map-background {
    position: relative;
    background-position:center;
    background-size: 100% 100%;
}
.kvfysmfp {
    margin: 0 auto;
    overflow:scroll !important;
    background-color: #fff;
    height: 60vh;
    border: 1px solid grey;
}