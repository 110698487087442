.user-tablet {
  &__line {
    display: flex;
    padding: 0 12px 0 24px;

    &--header {
      padding-top: 10px;
      padding-bottom: 8px;
      font-weight: 500;
      font-size: 12px;
      line-height: 32px;
      letter-spacing: 0.5px;
      color: #777777;
    }
  }

  &__body {
    font-weight: 500;
    font-size: 12px;
    line-height: 32px;
    letter-spacing: 0.5px;
    color: #010101;

    .user-tablet__line {
      cursor: pointer;
      padding-top: 7px;
      padding-bottom: 4px;

      &:nth-child(odd) {
        background: #EFEFEF;
      }
    }
  }

  &__info {
    position: absolute;
    top: 0;
    left: 22px;
    width: 19px;
    height: 29px;
    display: block;

    &.red {
      color: #E10505;
    }
  }

  &__error {
    font-size: 11px;
    line-height: 32px;
    letter-spacing: 0.5px;
    color: #E71A1A;
    position: absolute;
    top: 13px;
  }

  &__column {
    position: relative;

    &--1 {
      width: 106px;
      margin-right: 20px;
      flex-shrink: 0;
      position: relative;
    }

    &--2 {
      width: 104px;
      flex-shrink: 0;
    }

    &--3 {
      width: 50px;
      flex-shrink: 0;
    }

    &--4 {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      color: #4F4F4F;
    }
  }
}
.shared-table {
  margin-top: 20px;
  padding-bottom: 60px;


  &__header {
    font-size: 12px;
    line-height: 32px;
    letter-spacing: 0.5px;
    color: #777777;
  }

  &__barcode,
  &__quantity {
    font-size: 11px;
    line-height: 30px;
    font-weight: 300;

  }

  &__barcode {
    color: #777777;
  }

  &__body {
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.5px;
    color: #010101;

    .shared-table__line {
      cursor: pointer;
      padding-top: 12px;
      padding-bottom: 12px;
      color: #333;

      &:nth-child(odd) {
        background: #EFEFEF;
      }
    }
  }

  &__line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 14px 0 24px;
    position: relative;

    &--dis {
      &:before {
        width: 100%;
        height: 100%;
        position: absolute;
        background: rgba(79, 128, 255, 0.2);
        border: 2px solid #5BA7FF;
        left: 0;
        top: 0;
        box-sizing: border-box;
        z-index: 16;
      }
    }
  }

  &__column {
    &--1 {
      width: 35%;
      white-space: nowrap;
      overflow: hidden;
    }

    &--2 {
      width: 70px;
    }

    &--3 {
      width: 35px;
    }

    &--4 {
      width: 70px;
      display: flex;
      position: relative;

      .checkbox {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: calc(100% - 16px);
      }
    }

    &--inventory {
      white-space: nowrap;
      overflow: hidden;

      &--1 {
        font-size: 15px;
        width: 85%;
      }
      &--2 {

      }
    }
  }

  &__icon {
    display: block;
    color: #a6a6a6;

    &--active {
      color: #063d54;
    }

    &--white {
      color: #fff;
    }
  }

  &--checkbox {
    .shared-table__line {
      padding-right: 28px;
    }

    .int {
      margin-left: -14px;
    }
  }
}
.checkbox {
  cursor: pointer;
  $self: &;

  input:checked + * {
    #{$self}__front {
      opacity: 1;
      transform: scale(1) translate(5px, -3px);
    }
  }

  &__wrap {
    display: block;
    position: relative;
  }

  &__back {
    display: block;
    position: absolute;
    width: 20px;
    height: 20px;
    background: #fb9905;
    border-radius: 3px;
  }

  &__front {
    opacity: 0;
    transform: scale(0) translate(0, 0);
    position: relative;
    display: block;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    color: #fff;
    width: 22px;
    height: 20px;
  }
}

.picking-chckbox {
  padding-top: 50px;
  padding-bottom: 54px + 56px;
}

.block-info {
  display: flex;
  padding-left: 16px;
  padding-top: 6px;
  border-bottom: 1px solid #e3e3e3;
  padding-bottom: 5px;

  &__column {
    margin-right: 17px;

    &--type-2 {
      margin-right: 27px;
    }

    &:last-child {
      margin-right: unset;
    }
  }

  &__label {
    font-size: 12px;
    letter-spacing: 0.5px;
    color: #777777;
    margin-bottom: -2px;
  }

  &__title {
    font-weight: 500;
    font-size: 13px;
    line-height: 32px;
    letter-spacing: 0.5px;
    color: #4f4f4f;
    margin-bottom: -3px;
  }

  .die {
    font-size: 10px;
    padding: 4px 7px 4px 8px;
    box-shadow: unset;
  }
}
.int {
  display: flex;
  $self: &;

  &__input {
    height: 18px;
    text-align: center;
    border: 1px solid #27ae60;
    width: 32px;
    font-weight: 500;
    font-size: 12px;
    line-height: 32px;
    letter-spacing: 0.5px;
    color: #010101;
  }

  &__btn {
    border: 1px solid #27ae60;
    color: #fff;
    font-weight: 500;
    font-size: 11px;
    width: 19px;
    height: 18px;
    cursor: pointer;
    padding-left: 6px;
    padding-top: 3px;
    background: #27AE60;
    align-self: center;

    &--left {
      border-radius: 3px 0 0 3px;
    }

    &--left--hidden {
      visibility: hidden;
    }

    &--right {
      border-radius: 0 3px 3px 0;
    }
  }

  &--checkbox {
    #{$self}__btn {
      opacity: 0;
    }

    #{$self}__input {
      border: none;
      margin-left: -8px;
    }
  }
}

.shared-table__line.active {
  background-color: lightblue !important;
}
