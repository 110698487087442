.kvfysmfp {
    margin: 0 auto;
    overflow:scroll !important;
    background-color: #fff;
    height: 35vh;
    border: 1px solid grey;
}
.shelf-container {
    display: table;
}

/* shelf table */
.shelf-container table {
	background: #ccc;
	border-left: 9px solid #e55112;
	border-right: 9px solid #e55112;
	
	td, th {
	  padding: 1px 5px;
      background: #ccc;
	}
	tr {
		padding-bottom: 9px;
        display: block;
        position: relative;
	}
    tr::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: -3px;
        right: -3px;
        height: 9px;
        background: #E55112;
    }
	tr:last-child {
		&::after {
		    display: none;
		}
	}
	tr div {
	  background: linear-gradient(#eaeaea, #ffffff);
	  width: 125px;
	  height: 50px;
	  line-height: 50px;
	  text-align: center;
	  border: 1px solid black;
	  border-radius: 4px;
	  background-color: #fff;
      cursor: pointer;
	  margin-top: 10px;
	  font-size: 1rem;
	  font-weight: 500;
	}
	.selected {
		background: linear-gradient(#1af775, #0ea04b);
	}
	.filled {
	    background: linear-gradient(#d9f1ff, #2d9cdb);
	}
}
