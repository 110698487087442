.container {
    padding: 40px;
    height: 75vh !important;
}
.title {
    text-align: left;
    font-size: 18px;
    font-weight: 500;
    padding-bottom: 30px;

    span {
        text-decoration: underline;
    }
}
.block {
    margin-bottom: 40px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
}
.col-input {
    width: 85%;
}
.col-btn {
    position:relative;
    direction: rtl;

    img {
        position:absolute;
        top: 17px;
        right: auto;
        width: 54px;
        height: 23px;
    }
}
.btn {
    color: #FFFFFF;
    background: #27AE60;
    border: none;
    border-radius: 4px;
    width: 54px;
    height: 54px;
    padding: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}
