.btn {
    color: #FFFFFF;
    background: #27AE60;
    border: none;
    border-radius: 4px;
    width: 56px;
    height: 56px;
    padding: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    img {
        position:absolute;
        top: 15px;
        right: auto;
        width: 56px;
        height: 26px;
    }
}