.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #2D9CDB;
  height: 50px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 60;

  &__arrow {
    width: 37px;
    height: 34px;
    margin-right: 15px;
  }

  &__left {
    color: #fff;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-weight: 500;
    font-size: 20px;
    letter-spacing: 0.5px;
  }

  &__right {
    display: flex;
    align-items: center;
    color: #fff;
  }

  &__info {
    width: 36px;
    height: 36px;
    color: #fff;

    &--message {
      width: 35px;
      height: 27px;
    }
  }

  &__link-info {
    margin-right: 18px;

    span {
     cursor: pointer;
    }
  }

  &__more {
    width: 24px;
    height: 24px;
    color: #fff;
    cursor: pointer;
    user-select: none;
  }

  &__wrap-more {
    input:checked + * {
      opacity: 1;
      transform: scale(1);
    }
  }
}

.order-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-right: 12px;
  margin-top: 22px;
  border-bottom: 1px solid #E3E3E3;
  padding-bottom: 13px;
  padding-left: 10px;

  &__title {
    font-size: 16px;
    letter-spacing: 0.5px;
    color: #232323;
    padding-left: 15px;
    font-weight: 500;
    margin-bottom: 8px;
  }

  &__desc {
    font-size: 13px;
    line-height: 25px;
    letter-spacing: 0.5px;
    color: #606060;
    padding-left: 15px;
  }

  &__right {
    margin-top: -11px;
  }

  &--message {
    border-bottom: unset;
  }
}

