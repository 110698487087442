.user-tablet {
  &__line {
    display: flex;
    padding: 0 12px 0 24px;

    &--header {
      padding-top: 10px;
      padding-bottom: 8px;
      font-weight: 500;
      font-size: 12px;
      line-height: 32px;
      letter-spacing: 0.5px;
      color: #777777;
    }
  }

  &__body {
    font-weight: 500;
    font-size: 12px;
    line-height: 32px;
    letter-spacing: 0.5px;
    color: #010101;

    .user-tablet__line {
      cursor: pointer;
      padding-top: 7px;
      padding-bottom: 4px;

      &:nth-child(odd) {
        background: #EFEFEF;
      }
    }
  }

  &__info {
    position: absolute;
    top: 0;
    left: 22px;
    width: 19px;
    height: 29px;
    display: block;

    &.red {
      color: #E10505;
    }
  }

  &__error {
    font-size: 11px;
    line-height: 32px;
    letter-spacing: 0.5px;
    color: #E71A1A;
    position: absolute;
    top: 13px;
  }

  &__column {
    position: relative;

    &--1 {
      width: 106px;
      margin-right: 20px;
      flex-shrink: 0;
      position: relative;
    }

    &--2 {
      width: 104px;
      flex-shrink: 0;
    }

    &--3 {
      width: 50px;
      flex-shrink: 0;
    }

    &--4 {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      color: #4F4F4F;
    }
  }
}
