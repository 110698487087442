body {
  margin: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

div#root {
    width: 100%;
    height: 100%;
}

div#root .App {
   width: 100%;
   height: 100%;
}

a {
  font-family: inherit;
  color: inherit;
  text-decoration: none;
}

input {
  font-family: inherit;
  color: inherit;
  background: transparent;
  border: none;
  outline: none;
}

button {
  width: auto;
  padding: 0;
  margin: 0;
  overflow: visible;
  font: inherit;
  line-height: normal;
  color: inherit;
  background: transparent;
  border: none;
  outline: none;
}

* {
  box-sizing: border-box;
}

body,
html {
  width: 100%;
  height: 100%;
}

img {
  display: block;
}

.button {
  font-weight: 500;
  font-size: 22px;
  color: #fff;
  width: auto;
  padding: 0;
  margin: 0;
  overflow: visible;
  background: transparent;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;

  &--blue {
    width: 254px;
    height: 57px;
    background: #045EB1;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.25);
    border-radius: 40px;
  }
}

.control {
  width: 100%;
  border: none;
  outline: none;

  &__label {
    display: block;
    font-size: 20px;
    line-height: 23px;
    color: #fff;
    margin-bottom: 5px;
    padding-left: 5px;
  }

  &__input {
    border: 2px solid transparent;
    padding: 0 20px;
    width: 100%;
    height: 50px;
    background: #CBCBCB;
    border-radius: 10px;
    font-size: 21px;
    color: #000;

    &:focus,
    &:valid {
      background: #F9F9F9;
    }

    &--error {
      border-color: #F23D3D;
    }
  }
}
