.info-select {
  padding-top: 17px;
  padding-bottom: 3px;

  &__inner {
    width: max-content;
    margin: 0 auto;
    padding: 12px 22px;
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 0.5px;
    color: #727272;
    background: rgba(229, 255, 209, 0.13);
    border: 1px solid #C8C8C8;
    box-sizing: border-box;
    border-radius: 16px;
    line-height: 32px;
  }

  &--custom {
    .info-select__inner {
      padding-top: 9px;
      padding-left: 15px;
      padding-bottom: 7px;
    }
  }
}

.menu {

  opacity: 0;
  transform: scale(0);
  position: absolute;
  background-color: #fff;
  border-radius: 0 0 5px 5px;
  right: 0;
  top: 100%;
  width: 130px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.175);

  &__link {
    text-align: center;
    padding: 8px 0;
    font-size: 14px;
    cursor: pointer;
    user-select: none;
  }
}
